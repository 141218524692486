import React from 'react';
import "../styles/ourstory.css";

function OurStory (){
  const ourStory_img = require("../assets/ourstory.png");
  return(
    

    <div className="container text-center">
    <div className="row align-items-start">
     

      <div class="col-12 align-self-center story_img img">
          <img src={ourStory_img}  class="" alt="praying hands on top of bible" />
        </div>
        <div className='row story'>Our Story</div>

      <div className="col ourstory">
      Inspired by the desires God has put in Meylin Baiza's heart , she started the work in her former church PTL Temple of Living God in August 2022 with Nathalie Cuello. They had former Reverend Dr. Nicanor Gonzales support. They were able to provide non-perishable food, hygiene items, clothes, toys , diapers and wipes with the donations of the church and organizations. A year later Gracious love is moving to work independently. One of the main needs Meylin saw in the Bronx is the need for diapers and wipes, the focus of Gracious love is to provide these needs to low income families. Meylin is working with a group of people that are volunteering their time to seek resources.
      </div>
      </div>
      </div>
  )
 
}
export default OurStory;