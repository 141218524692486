import react from "react";
import "../styles/footer.css";
import { useForm, ValidationError } from "@formspree/react";

const instagram=require("../assets/footer/instagram.png");
const facebook=require("../assets/footer/facebook.png");
const email=require("../assets/footer/email.png");
const give=require("../assets/give.png");

function Footer (){
  const [state, handleSubmit] = useForm("mzbqwgoe");

return (
<>  



  <div className="row content justify-content-center space">
    <div className="col-12 ">
  
<div class="container footer_  judson-regular">
  <footer>
    <div class="row">
    {/* <div class="col-md-5">
        <form>
          <h3>Contact Us</h3>
          <form onSubmit={handleSubmit}>
        <div className="col-8">
          <div className="mb-3">

          <label htmlFor="name" className="form-label">
               Name

               <input
              type="text"
              name="name"
              className="form-control"
              placeholder="Name"
            />
            </label>

  
          
          </div>
        </div>

        <div className="col-8">
          <div className="mb-3">
        <label for="exampleFormControlTextarea1" className="form-label message">
        Petition

        <textarea
        name="prayer_request"
        className="form-control"
        id="exampleFormControlTextarea1"
        rows="3"
      ></textarea>

      </label>
     

</div></div>

<ValidationError 
      prefix="Email" 
      field="email"
      errors={state.errors}
    />
 
    <ValidationError 
      prefix="Message" 
      field="message"
      errors={state.errors}
    />
    <button class="btn_s" type="submit" disabled={state.submitting}>
      Submit
    </button>

      </form>
    
        </form>
      </div> */}

      <div class="col-12 col-md-8 mb-3">
        <h3>Support Our Ministry
        <img className="give" src={give} alt="giving hand holding heart"/>
        </h3>
       <p>Support our ministry by contributing donations and financial assistance; your generosity enables us to make a greater impact and fulfill our mission.</p>
      </div>

   

      <div class="col-12 col-md-2 mb-3">
        <h3>Social Media</h3>
        <ul class="nav">
          <li class="nav-item mb-2"><a href="https://www.facebook.com/profile.php?id=61554381745099" class="nav-link p-0 text-body-secondary" target="_blank"> <img className="facebook" src={facebook} alt="facebook icon"/></a></li>
          <li class="nav-item mb-2"><a href="https://www.instagram.com/graciousloveministry/" class="nav-link p-0 text-body-secondary" target="_blank"> <img className="instagram" src={instagram} alt="instagram icon"/></a></li>
        </ul>
      </div>


    </div>

    <div class="d-flex flex-column flex-sm-row justify-content-between email_address">
      <p class="email">graciousloveministry@gmail.com</p>
    </div>
  </footer>
</div>
  

     
        </div>

      
    
  
  </div>

  
  </>
)

}
export default Footer;