import React from "react";
import ReactDOM from 'react-dom/client';
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Footer from "./components/Footer.js";

const root= ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <div className="spacing">  
    <App/>
    </div>
    <Footer/>
  </React.StrictMode>
)
reportWebVitals();