import React from "react";
import "../styles/photos.css";
import { outreachEvent } from "./images.js";

function Photos() {
  return (
    <div class="container text-center">
      <div class="row align-items-start">
        <>
          {outreachEvent.map((outreach) => (
            <div class="col maincard">
              <img class="photo" src={outreach.image} />
            </div>
          ))}
        </>
      </div>
    </div>
  );
}

export default Photos;
