import react from "react";

function ThankYou() {
  return (
    <>
      <div className="contain container">
 
        <div className="col">
          <div className="response">
            <h1>
              Thank you for contacting us.
              <br />
            </h1>
          </div>
        </div>
        
      </div>
    </>
  );
}
export default ThankYou;
