import React, { Component } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Carousel from "./components/Carousel";
import Events from "./components/Events";
import OurStory from "./components/OurStory";
import OurTeam from "./components/Ourteam";
import ContactForm from "./components/Contact";
import Photos from "./components/Photos";
import Footer from "./components/Footer";
import "./styles/navbar.css";
import "./styles/app.css";
import Prayer from "./components/Prayer";

const logo = require("./assets/logo-jumbotron.png");

class App extends Component {
  render() {
    return (
      <Router>
        {/* first nav */}
        <nav class="navbar inter">
          <div class="container-fluid">
            <span class="navbar-brand mb-0 h1">Gracious Love</span>
              <div class="top_navigation">
                <Link to="/">
                  <li className="nav-item">
                    <a className="nav-link topnav" href="#">
                      DONATE
                    </a>
                  </li>
                </Link>
                <Link to="/contact">
                  <li className="nav-item">
                    <a className="nav-link topnav" href="#">
                      CONTACT US
                    </a>
                  </li>
                </Link>
              </div>
            </div>
        </nav>
{/* second nav */}
        <nav className="navbar navbar-expand-lg bg-body p-0">
          <div className="container-fluid extended judson-bold ">
            <Link to="/">
              <img className="smallLogo" src={logo} alt="logo" />
            </Link>

            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNavDropdown"
              aria-controls="navbarNavDropdown"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNavDropdown">
              <ul className="navbar-nav items">
                {/* ABOUT US----------- */}
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle evenspace"
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    ABOUT US
                  </a>

                  <ul className="dropdown-menu pt-4 pb-4">
                    <Link to="/ourstory">
                      <li>
                        <a className="dropdown-item" href="#">
                          Our Story
                        </a>
                      </li>
                    </Link>

                    {/* OURTEAM------------ */}
                    <Link to="/ourteam">
                      <li>
                        <a className="dropdown-item" href="#">
                          Our Team
                        </a>
                      </li>
                    </Link>
                  </ul>
                </li>

                {/* PHOTOS--------------- */}
                <Link to="/photos">
                <li className="nav-item">
          <a className="nav-link" href="#">GALLERY</a>
        </li>
        </Link>

                {/*DONATIONS-----------  */}
                <Link to="/prayer">
                  <li className="nav-item">
                    <a className="nav-link" href="#">
                      PRAYER
                    </a>
                  </li>
                </Link>

                {/* CONTACT--------- */}
                {/* <Link to="/"> */}
                  <li className="nav-item">
                    <a className="nav-link" href="/#events">
                      EVENTS
                    </a>
                  </li>
                {/* </Link> */}
              </ul>
            </div>
          </div>
        </nav>

        {/* Routes ----------------*/}
        <Routes>
          <Route exact path="/ourstory" element={<OurStory />}></Route>
          <Route exact path="/" element={<Carousel />}></Route>
          <Route exact path="/events" element={<Events />}></Route>
          <Route exact path="/ourteam" element={<OurTeam />}></Route>
          <Route exact path="/photos" element={<Photos />}></Route> 
          <Route exact path="/prayer" element={<Prayer />}></Route>
          <Route exact path="/contact" element={<ContactForm />}></Route>
        </Routes>

        {/* <Footer/> */}
      </Router>
    );
  }
}
export default App;
