import React from "react";
import "../styles/ourteam.css";
import { team } from "./staff.js";

function OurTeam() {
  return (
    <>
      {team.map((staff) => (
        <>
          <div className="card mb-3">
            <div className="row g-0">
              <div className="col-md-4">
                <img
                  src={staff.image}
                  className="img-fluid rounded-start staff_img"
                  alt={staff.alt}
                />
              </div>
              <div class="col-md-8">
                <div class="card-body">
                  <h5 class="card-title">{staff.name}</h5>
                  <h6>{staff.title}</h6>
                  <br></br>
                  <p class="card-text">{staff.about}</p>
                </div>
              </div>
            </div>
          </div>
        </>
      ))}
    </>
  );
}
export default OurTeam;
