import React from "react";
const upcoming_outreach = require("../assets/gracious_love/diapers.jpg");

function Events() {
  return(<>
  {/* ------Upcoming Event---------------------- */}
  
        <div className="row col_events" id="events"> 
      
          <div className="col event_">
          <h2 className="judson-bold title_event">Upcoming Event</h2>
            <p className="row_event">
              God bless you all! We are collecting diapers again to provide to
              single moms. These moms are in a house where they are being helped
              with their basic needs, including a place to stay, food, and
              companionship during their birth process. The organization does
              its best to help find a stable place for these moms. The mothers
              are also being protected from abusive relationships, which is why
              we are keeping the name of the place private. DM us for more
              information on how you can provide diapers and wipes. Thank you!
            </p>
          </div>
      
          <img className="col row_img" src={upcoming_outreach} alt="upcoming outreach flyer" />
          </div>
     
    </>
  );
}
export default Events;
