import React from "react";
import { useForm, ValidationError } from "@formspree/react";
import ThankYou from "../components/thankYou.js";
import "../styles/contact.css";
const logo = require("../assets/logo-jumbotron.png");


function ContactPrayer() {
  const [state, handleSubmit] = useForm("mzbqwgoe");
  if (state.succeeded) {
    return <>
      <ThankYou/> 
    </>;
  }
  return (
    
    <> 
  
    <div className="outer"> 
    <h2 id="outer">Prayer Request</h2>
    <div className="container bordered" >
      <div className="row detailRow">
        <form onSubmit={handleSubmit}>
          <div className="col-8">
            <div className="mb-3">

            <label htmlFor="name" className="form-label">
                 Name
              </label>

              <input
                type="text"
                name="name"
                className="form-control"
                placeholder="Name"
              />


            
            </div>
          </div>

          <div className="col-8">
            <div className="mb-3">
          <label for="exampleFormControlTextarea1" className="form-label message">
          Petition
        </label>
        <textarea
          name="prayer_request"
          className="form-control"
          id="exampleFormControlTextarea1"
          rows="3"
        ></textarea>

</div></div>

<ValidationError 
        prefix="Email" 
        field="email"
        errors={state.errors}
      />
   
      <ValidationError 
        prefix="Message" 
        field="message"
        errors={state.errors}
      />
      <button class="btn_s" type="submit" disabled={state.submitting}>
        Submit
      </button>

        </form>
        
      </div>

    </div>
    </div>
    </>
  );
}
export default ContactPrayer;
