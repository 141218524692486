
const outreach1 = require("../assets/gracious_love/outreach1.jpeg");
const outreach2 = require("../assets/gracious_love/outreach2.jpeg");
const outreach3 = require("../assets/gracious_love/outreach3.jpeg");


export const outreachEvent=[
  {
    "id":1,
    "alt":"outreach event diapers and wipes",
    "image":outreach1,
    "about":"....etc."
  },
  {
    "id":2,
    "alt":"outreach event",
    "image":outreach2,
    "about":"....etc."
  },
  {
    "id":3,
    "alt":"outreach event",
    "image":outreach3,
    "about":"....etc."
  },
]