import React, { Component } from "react";
import "../styles/donations.css";
import ContactPrayer from "../components/ContactPrayer";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

const praying = require("../assets/prayer/prayinghands.jpeg");
const mother = require("../assets/prayer/mother.jpeg");
const homeless = require("../assets/prayer/homeless.jpg");
const candle = require("../assets/prayer/candle.jpg");

class Prayer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <div class="col-12 m-4">
          <h1 class="prayer_page">Join us to Pray for </h1>
        </div>

{/* here and after------ */}
{/* picture */}
<div class="container main_container">
        <div class="col-12 align-self-center">
          <img src={praying} class="church_img d-block" alt="praying hands on top of bible" />
        </div>

{/* content */}
          <div class="row prayer_box mt-4">
            <div class="col-12">
              <h2 class="prayer_title">For the Community Work Ahead</h2>
            </div>

            <div class="container">

              <div class="row verse">
              <div class="col-12">Ephesians 2:10</div>
                <div class="col-12">
                  <p>
                  For we are His workmanship, created in Christ Jesus for good works, which God prepared beforehand that we should walk in them. 
                  </p>
                </div>
              </div>
            </div>
          </div>

</div>
{/* prayer */}
<div class="prayers">
<div class="col-12 mt-4">
    <h2 class="prayers_headline">Prayer</h2>
    <p class="pray">
    Give us grace and guidance of the journey ahead. Bless the work before us. Let us listen for your voice, that our hearts be open to the beckoning of your spirit in this labor. Let us move at the impulse of your love. Unite your people and increase our modest offerings, Lord that all echo to your Glory! 
    </p>
</div>
</div>





{/* make me a container Mother*/}
<div class="container main_container">
        <div class="col-12 align-self-center">
          <img src={mother} class="church_img d-block" alt="mother carrying a child" />
        </div>

{/* content */}
          <div class="row prayer_box mt-4">
            <div class="col-12">
              <h2 class="prayer_title">Single Parents</h2>
            </div>

            <div class="container">

              <div class="row verse">
              <div class="col-12">Genesis 16:13a</div>
                <div class="col-12">
                  <p>
                  “You are the God who sees me”
                  </p>
                </div>
              </div>
            </div>
          </div>

</div>
{/* petitions */}
<div class="petitions">
<div class="col-12 mt-4">
    <h2 class="prayers_headline">Petitions</h2>
    <ul class="petition_list">
      <li>For wisdom and understanding. </li>
      <li>For emotional healing and stability of the parent and children. </li>
      <li>For finances stability.</li>
      <li>For daily provision of their basic needs (like food, home, clothes, transportation and work). </li>
      <li>For co-parenting guidance </li>
      <li>For resources ; economically, education, health and work. </li>
      <li> For physical strength and good health of the parent and child. </li>
      <li>For emotional support.</li>
    </ul>
</div>
</div>

{/* prayer */}
<div class="prayers">
<div class="col-12 mt-4">
    <h2 class="prayers_headline">Prayer</h2>
    <p class="pray">
    Lord you hear and see the cry of each single parent in the world, as you did with Hagar. Lord we are grateful you incline your ear to those broken and crushed in spirit. As their hearts are grieving of the lost of their marriage, may you comfort each side and the children involved. We supplicate for restoration in each family whether is coming together or learning to do co-parenting. For those parents that are in their own may you be their refuge, their strong tower. We pray for emotional healing in each single parent, so they can raised healthy children. Lord ,Provide their basic needs each day, may they not lack any resources for themselves and children’s. May they have spiritual , emotional ,physical, financial support. In the name of Jesus Amen!
    </p>
</div>
</div>

{/* Homeless------------------------ */}
<div class="container main_container">
        <div class="col-12 align-self-center">
          <img src={homeless} class="church_img d-block" alt="homeless man" />
        </div>

{/* content */}
          <div class="row prayer_box mt-4">
            <div class="col-12">
              <h2 class="prayer_title">The Homeless</h2>
            </div>

            <div class="container">

              <div class="row verse">
              <div class="col-12">Psalm 10:17</div>
                <div class="col-12">
                  <p>
                  “O LORD, you hear the desire of the afflicted; you will strengthen their heart; you will incline your ear.”
                  </p>
                </div>
              </div>
            </div>
          </div>

</div>
{/* petitions */}
<div class="petitions">
<div class="col-12 mt-4">
    <h2 class="prayers_headline">Petitions</h2>
    <ul class="petition_list">
      <li>For a shelter for those who have lost their home.</li>
      <li>For daily provision of food.</li>
      <li>For mental, physical, and spiritual stability.</li>
      <li>For work stability.</li>
      <li>For the restoration of what they lost.</li>
    </ul>
</div>
</div>

{/* prayer */}
<div class="prayers">
<div class="col-12 mt-4">
    <h2 class="prayers_headline">Prayer</h2>
    <p class="pray">
    Lord fix our hope in you, taking into account of what was lost of what we couldn’t keep, comfort us. As we are broken due to the lost of our home, work , and our stability be our foundation. Restore us and what we have lost. We thank you for the presence of friends and neighbors that share our grief by showing up in the middle of our affliction. Shield us from the hurts we cannot bear. Shelter us in the fortress of your Love. Amen! 
    </p>
</div>
</div>

<div class="col-12 align-self-center">
          <img src={candle} class="church_img d-block" alt="image of candle" />
</div>
<ContactPrayer/>

      </>
    );
  }
}

export default Prayer;
