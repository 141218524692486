import React, { Component } from "react";
import "../styles/carousel.css";

// const outreach_img1 = require("../assets/gracious_love/outreach3.jpeg");
const outreach_img1 = require("../assets/gracious_love/main_image.png");
const bags = require("../assets/outreach_/bags.png");
const wipes = require("../assets/outreach_/wipes.png");
const brochure = require("../assets/outreach_/brochure.png");
const diaper_icon = require("../assets/outreach_/diaper_icon.png");
const diet = require("../assets/outreach_/diet.png");
const upcoming_outreach = require("../assets/gracious_love/diapers.jpg");

class Carousel extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="main_">
        <div class="row main_page">
          <div class="col main_page">
            <div className=" gl_intro">
              <p>
                <span className="gl judson-bold">Gracious Love</span> supports
                low-income families by providing essentials like diapers and
                wipes, and sharing the Love of God; as an independent group, we
                trust in God's provision and collaborate with others to meet
                diverse family needs.
              </p>
             
            </div>
          </div>
          <div class="col-4 main_page">
            <img
              className="main_image"
              src={outreach_img1}
              alt="outreach image giving diapers"
            />
          </div>
        </div>

        {/* Scripture */}
        <div className="container deuteronomy judson-bold">
          <div className="row">
            <div className="col-12 scripture">
              “He defends the cause of the fatherless and the widow, and loves
              the foreigner residing among you, giving them food and clothing.”
            </div>
            <div className="col-12">Deuteronomy 10:18</div>
          </div>
        </div>

        {/* Outreach */}
        <div className="outreach judson-regular col-12">
          <div className="col-4 donations">
            <img className="donation_image bags" src={bags} alt="bags" />
            <img className="donation_image wipes" src={wipes} alt="wipes" />
          </div>
          <div className="col-8 outreach_">
            <div className="title judson-bold">Outreach</div>
            <p className="judson-regular">
              While independently collaborating with other organizations for a
              broader impact, we have hosted events in the past and will be
              hosting upcoming events to continue to support single parents and
              sheltered families by providing{" "}
            </p>
            <ul className="lists">
              <li>
                <img
                  className="icons judson-regular "
                  src={brochure}
                  alt="brochure icon"
                />{" "}
                Resources
              </li>
              <li>
                <img
                  className="icons judson-regular "
                  src={diet}
                  alt="diet icon"
                />{" "}
                Food/Clothing
              </li>
              <li>
                <img
                  className="icons judson-regular "
                  src={diaper_icon}
                  alt="diaper icon"
                />{" "}
                Diapers & wipes
              </li>
            </ul>
          </div>
        </div>

        {/* ------Upcoming Event---------------------- */}
        {/* <div className="col_events"> */}
          <div className="row col_events" id="events"> 
          {/* <h2 className="judson-bold title_event">Upcoming Event</h2> */}
          <div className="col event_">
          <h2 className="judson-bold title_event">Upcoming Event</h2>
            <p className="row_event">
              God bless you all! We are collecting diapers again to provide to
              single moms. These moms are in a house where they are being helped
              with their basic needs, including a place to stay, food, and
              companionship during their birth process. The organization does
              its best to help find a stable place for these moms. The mothers
              are also being protected from abusive relationships, which is why
              we are keeping the name of the place private. DM us for more
              information on how you can provide diapers and wipes. Thank you!
            </p>
          </div>
          {/* <div className="row_img"> */}
          <img className="col row_img" src={upcoming_outreach} alt="upcoming outreach flyer" />
          </div>
          {/* </div>   */}
        {/* </div> */}
        {/* Our vision & mission */}

        <div className="vision_mission_title judson-bold">
          Our Vision & Mission
        </div>
        <div className="vission_mission_content judson-regular ">
          <p className="vission_ judson-regular">
            Our vision to demonstrate God’s love and expose the biblical social
            economy growth through Christ provision. Our mission is to provide
            essential items, such as diapers and wipes, to families while
            connecting them with organizations that can address their specific
            needs.
          </p>
        </div>

        {/* Values */}
        <div className="col-12"></div>
        <div className="values_title judson-bold">Values</div>
        <div className="vission_mission_content judson-regular ">
          <div className="vission_ judson-regular">
            We foster a strong sense of community and connection by regularly
            sharing stories, testimonials, and updates that exemplify and
            reinforce our three core values.
          </div>
        </div>

        {/* pillars */}
        <div class="pillars">
          <div class="mercy judson-regular ">
            <div class="pillar judson-bold">Mercy</div>
            <div class="verse_ judson-bold">Lamentations 3:23</div>
            <div class="pillar_content">
              “God’s mercy are new everyday”In response to His grace towards us,
              we aspire to serve our neighbors in need, allowing them to
              experience God's mercy through our acts of service."
            </div>
          </div>

          <div class="justice judson-regular ">
            <div class="pillar judson-bold">Justice</div>
            <div class="verse_ judson-bold">Psalm 33:5, Isaiah 30:18</div>
            <div class="pillar_content">
              “God's justice, love, and compassion for His creation compel us to
              heed His command to look out for "the least of these."
            </div>
          </div>

          <div class="service judson-regular ">
            <div class="pillar judson-bold">Serve</div>
            <div class="verse_ judson-bold">Matthew 20:28</div>
            <div class="pillar_content">
              “Our service is to imitate the transforming influence of the love
              of Jesus Christ, by imitating the steps of compassion, servitude,
              wisdom and restoration that Jesus took."
            </div>
          </div>
        </div>

        <div>
        <p className="judson-regular thank_you">
              Thank You to all of our Supporters and to our independent sponsors
        </p>
        </div>
      </div>
    );
  }
}

export default Carousel;
