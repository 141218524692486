const meylin = require("../assets/team/meylin.jpeg");
const helen=require("../assets/team/helen.jpg");
const antonia=require("../assets/team/nana.jpg");
const jen=require("../assets/team/jen.jpeg");

export const team=[
    {
      "id":1,
      "name":"Meylin Baiza",
      "alt":"picture of Meylin",
      "image":meylin,
      "title":"Administrator",
      "about":"Meylin's heart is for people to know God! She has witnessed God’s love for humanity and human rights through the Old and New Testament. Her motivation is the love God has poured into her life. Meylin wants to be an instrument of God to plant the seed and let the Holy Spirit do the rest. Through her work in her former church, she saw the need for diapers and wipes that families are facing. Her goal is to bless low-income families and connect them with other organizations that can help them with additional needs they may have."
    },
    {
      "id":2,
      "name":"Helen Feliz del Castillo ",
      "alt":"image of helen",
      "image":helen,
      "title":"Administrator Assistant Volunteer",
      "about":"Helen is a loving mother, dedicated to her kids. Her love, dedication, and compassion for her kids will be a reflection of the work we will be doing through G.L. Helen will be a blessing to single mothers, single dads, and families in shelters."
    },    
    
    {
      "id":3,
      "name":"Antonia Jimenez",
      "alt":"image of antonia",
      "image":antonia,
      "title":"Spiritual Care Coordinator Volunteer",
      "about":"Antonia is a woman devoted to God and her family. Her heart is to serve and be a blessing to those around her. We are glad that she continues to be part of the group. As a single mother, she knows how much the love of God can equip you to face the trials each parent goes through."
    },

    {
      "id":4,
      "name":"Jennifer Perez",
      "alt":"image of jen",
      "image":jen,
      "title":"Web Developer",
      "about":"Jennifer has a servant's heart and has volunteered her time to help us create a website, and she will continue volunteering to maintain the site. Jennifer’s heart has always been to share the love of God with others, bringing her joy. She is using her skills to be a blessing to others."
    }
  ]
